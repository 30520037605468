import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Grid } from "page_components/investments"

const Investment = () => {
  const title = "Portfel nieruchomości"

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Marzysz o apartamencie z widokiem na jezioro, morze lub zapierające dech w piersiach góry? Bez względu na to jaki kierunek wybierzesz jesteśmy po to, aby Ci pomóc. ",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Grid />
    </Layout>
  )
}

export default Investment
